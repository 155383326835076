import React from "react";
import { withRouter } from 'react-router-dom';
import BannerMessage from './BannerMessage';
import {Auth} from 'aws-amplify';
import {contactHelpMessage} from "../constants";
import {makeApiCall} from "./helpers/kfs_api";
import {DelayedCancelableOperation} from "./helpers/react_select";
import BuildingNameField from "./form/BuildingNameField";
import BuildingCodeField from "./form/BuildingCodeField";
import AssetTagField from "./form/AssetTagField";
import RoomField from "./form/RoomField";
import ConditionField from "./form/ConditionField";
import ErrorMessage from "./form/ErrorMessage";
import InfoMessage from "./form/InfoMessage";

const validateForm = capAsset => {
    const errorMessages = [];
    if (!capAsset.building_code) {
        errorMessages.push('Building is required');
    }
    if (!capAsset.condition_code) {
        errorMessages.push('Condition is required');
    }
    if (!capAsset.room_number) {
        errorMessages.push('Room is required');
    }
    if (!capAsset.asset_tag) {
        errorMessages.push('Asset Tag is required');
    }
    return errorMessages.join('\n');
};

const buildCognitoLoginUrl = () => {
    const path = `https://${process.env.REACT_APP_AWS_COGNITO_DOMAIN}/login`;
    const clientId = process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID_WEB;
    const cognitoRedirectUrl = window.location.origin;
    return `${path}?response_type=token&client_id=${clientId}&redirect_uri=${cognitoRedirectUrl}`;
};

const redirectToCognitoLogin = () => {
    const targetUrl = buildCognitoLoginUrl();
    window.location.href = targetUrl;
}

class Home extends React.Component {
    constructor(props) {
        super(props);

        const idToken = localStorage.getItem('id_token');
        const netid = localStorage.getItem('netid');

        this.assetTagInputRef = React.createRef();
        this.saveButtonRef = React.createRef();
        this.scannerRef = React.createRef();

        this.buildingNameSearcher = new DelayedCancelableOperation(this.handleBuildingApiCall, 500);
        this.buildingCodeSearcher = new DelayedCancelableOperation(this.handleBuildingApiCall, 500);

        this.state = {
            capAsset: {
                capital_asset_number: '',
                building_code: '',
                building_name: '',
                room_number: '',
                asset_tag: '',
                capital_asset_description: '',
                serial_number: '',
                condition_code: '',
                organization: ''
            },
            previousFieldValues: {
                building_code: '',
                building_name: ''
            },
            idToken,
            netid,
            rooms: [],
            loading: false,
            loadingRoomLookup: false,
            errorMessage: '',
            successMessage: '',
            helpMessage: '',
            loadingMessage: '',
            scanning: false,
            isCameraAvailable: true
        };
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(currentUser => {
            if (!currentUser || !currentUser.signInUserSession) {
                return redirectToCognitoLogin();
            }
            const idToken = currentUser.signInUserSession.idToken.jwtToken;
            const netid = currentUser.attributes.name;
            this.setState({ loading: false, idToken, netid });
            
        }).catch(error => {
            console.error(error);
            redirectToCognitoLogin();
        });
    }

    handleBuildingInputBlur = (fieldName, value) => {
        const oldValue = this.state.previousFieldValues[fieldName];
        const trimmedValue = value && value.trim();
        if (oldValue !== trimmedValue) {
            const oldUpperCaseValue = oldValue && oldValue.toUpperCase();
            const newUpperCaseValue = trimmedValue && trimmedValue.toUpperCase();
            if (oldUpperCaseValue !== newUpperCaseValue) {
                this.updateBuildingForInputBlur(fieldName, trimmedValue);
            } else {
                this.resetCapAssetFieldToPreviousValue(fieldName, oldValue);
            }
        } else if (value !== trimmedValue) {
            this.resetCapAssetFieldToPreviousValue(fieldName, oldValue);
        }
    }

    updateBuildingForInputBlur = (fieldName, value) => {
        this.updateBuildingFieldsPriorToPotentialSearch(fieldName, value);
        if (value && value.length) {
            this.updateBuildingForSingleSearchMatchIfPresent(fieldName, value);
        }
    };

    updateBuildingFieldsPriorToPotentialSearch = (fieldName, value) => {
        const valueIsNonEmpty = !!(value && value.length);
        const capAsset = { ...this.state.capAsset };
        const previousFieldValues = { ...this.state.previousFieldValues };
        capAsset[fieldName] = value;
        previousFieldValues[fieldName] = value;
        const stateToModify = {
            capAsset, previousFieldValues, loadingRoomLookup: valueIsNonEmpty
        };
        if (fieldName === 'building_code') {
            stateToModify.capAsset.room_number = '';
            stateToModify.rooms = [];
        }
        this.setState(stateToModify);
    };

    handleBuildingApiCall = async ({ buildingLabelField, query, idToken }) => {
        const trimmedQuery = query && query.trim();
        const path = `/campus/IT/buildings?${buildingLabelField}=${encodeURIComponent(trimmedQuery)}`;
        try {
        	const buildings = await makeApiCall({ path, idToken });
        	this.setState({ errorMessage: '' });
        	const buildingOptions = buildings.map(b => ({
                label: b[buildingLabelField],
                value: b.building_code,
                name: b.building_name
            }));
            return buildingOptions;
        } catch (error) {
        	this.setState({ errorMessage: 'Unable to get building list from KFS' });
        }
    };

    handleBuildingApiCallForCaseInsensitiveSingleMatch = async ({ buildingLabelField, query, idToken }) => {
        const buildingOptions = await this.handleBuildingApiCall({ buildingLabelField, query, idToken });
        const upperCaseQuery = query && query.trim().toUpperCase();
        if (buildingOptions && buildingOptions.length) {
            const filteredOptions = buildingOptions.filter(
                buildingOption => buildingOption.label.toUpperCase() === upperCaseQuery);
            if (filteredOptions.length === 1) {
                return filteredOptions[0];
            }
        }
        return null;
    };

    handleRoomListApiCall = async (buildingCode, idToken) => {
        const encodedBuildingCode = encodeURIComponent(buildingCode);
    	const roomStrings = await makeApiCall({ path: `/campus/IT/buildings/${encodedBuildingCode}/rooms`, idToken });
    	const rooms = roomStrings.map(r => ({ label: r, value: r }));
    	return rooms;
    };
    
    resetCapAssetFieldToPreviousValue = (fieldName, previousValue) => {
        const capAsset = { ...this.state.capAsset };
        capAsset[fieldName] = previousValue;
        this.setState({ capAsset });
    };

    updateBuildingForSingleSearchMatchIfPresent = async (fieldName, value) => {
        let buildingOption = null;
        let errorMessage = '';
        try {
            const idToken = this.state.idToken;
            buildingOption = await this.handleBuildingApiCallForCaseInsensitiveSingleMatch({
                buildingLabelField: fieldName,
                query: value,
                idToken
            });
        } catch (error) {
            console.error(error);
            errorMessage += error.message;
            buildingOption = null;
        }

        if (buildingOption) {
            await this.handleBuildingChange(buildingOption);
        } else {
            this.setState({ loadingRoomLookup: false, errorMessage });
        }
    };

    handleBuildingInputChange = (fieldName, value, action) => {
        if (action === 'input-change') {
            const capAsset = { ...this.state.capAsset };
            capAsset[fieldName] = value;
            this.setState({ capAsset });
        } else if (action === 'input-blur') {
            const inputValueFromState = this.state.capAsset[fieldName];
            this.handleBuildingInputBlur(fieldName, inputValueFromState);
        }
    };

    handleBuildingNameInputChange = (value, {action}) => {
        this.handleBuildingInputChange('building_name', value, action);
    };

    handleBuildingCodeInputChange = (value, {action}) => {
        this.handleBuildingInputChange('building_code', value, action);
    };

    handleBuildingSearch = async (buildingSearcher, buildingLabelField, query) => {
        if (query) {
            const idToken = this.state.idToken;
            const buildingOptions = await buildingSearcher.run({ buildingLabelField, query, idToken });
            return buildingOptions;
        } else {
            buildingSearcher.cancelPriorRunIfPending();
        }
        return [];
    };

    handleBuildingNameSearch = async query => {
        return await this.handleBuildingSearch(this.buildingNameSearcher, 'building_name', query);
    };

    handleBuildingCodeSearch = async query => {
        return await this.handleBuildingSearch(this.buildingCodeSearcher, 'building_code', query);
    };

    handleBuildingChange = async event => {
        const capAsset = { ...this.state.capAsset };
        const previousFieldValues = { ...this.state.previousFieldValues };
        capAsset.building_code = event.value;
        capAsset.building_name = event.name;
        capAsset.room_number = '';
        previousFieldValues.building_code = capAsset.building_code;
        previousFieldValues.building_name = capAsset.building_name;
        let errorMessage = "";

        this.setState({ loadingRoomLookup: true, capAsset, previousFieldValues });

        let rooms = [];
        try {
            rooms = await this.handleRoomListApiCall(capAsset.building_code, this.state.idToken);
        } catch (error) {
            console.error(error);
            errorMessage = 'Unable to get room list from KFS';
        }

        this.setState({ rooms, capAsset, loadingRoomLookup: false, loading: false, errorMessage });
    };

    handleRoomInputChange = (value, {action}) => {
        if (action === 'input-change') {
            const capAsset = { ...this.state.capAsset };
            capAsset.room_number = value;
            this.setState({ capAsset });
        }
    };

    handleRoomChange = event => {
        const capAsset = { ...this.state.capAsset };
        capAsset.room_number = event.value;
        this.setState({ capAsset });
    };

    handleAssetTagChange = event => {
        event.preventDefault();
        const fieldValue = event.currentTarget.value;
        const capAsset = { ...this.state.capAsset };
        capAsset.asset_tag = fieldValue;
        this.setState({ capAsset });
    };

    handleAssetTagKeyPress = async event => {
        if ((event.type === 'blur' || event.key === "Enter")) {
            const assetTag = event.currentTarget.value;
            event.preventDefault();

            this.handleAssetTagApiCall(assetTag);
        }
    };

    handleAssetTagApiCall = async assetTag => {
        try {
            const capAsset = {...this.state.capAsset};
            const assetTagTrimmed = assetTag && assetTag.trim();
            const assetTagInfo = assetTagTrimmed && await makeApiCall({path: `/asset/${encodeURIComponent(assetTagTrimmed)}`, idToken: this.state.idToken});
            capAsset.capital_asset_description = assetTag ? assetTagInfo.capital_asset_description : '';
            capAsset.serial_number = assetTag ? assetTagInfo.serial_number : '';
            capAsset.organization = assetTag ? assetTagInfo.organization_inventory_name : '';
            capAsset.capital_asset_number = assetTag ? assetTagInfo.capital_asset_number : '';

            const stateToUpdate = { capAsset, errorMessage: '' };
            if (assetTagTrimmed) {
                stateToUpdate.successMessage = '';
            }
            this.setState(stateToUpdate);
        } catch (error) {
            const errorMessage = "[Fetch Asset Info] " + error.message.replace("Object in KFS", `Asset with Tag "${assetTag}" in KFS`);
            const capAsset = {...this.state.capAsset};
            capAsset.capital_asset_description = '';
            capAsset.serial_number = '';
            capAsset.organization = '';
            capAsset.capital_asset_number = '';
            this.setState({ capAsset, errorMessage, successMessage: '' });
        }
    };

    handleConditionChange = conditionSelected => {
        const capAsset = { ...this.state.capAsset };
        capAsset.condition_code = conditionSelected.value;
        this.setState({ capAsset });
    };

    handleSave = async event => {
        event.preventDefault();

        const capAsset = {...this.state.capAsset};
        const loadingMessage = capAsset.asset_tag && !capAsset.capital_asset_number ? 'Generating Barcode Inventory Error Document (BCIE) in KFS': '';
        this.setState({ loading: true, loadingMessage, successMessage: '' });

        let errorMessage = validateForm(capAsset);
        if (errorMessage) {
            this.setState({ errorMessage, loading: false })
            return;
        }

        try {
            const idToken = this.state.idToken;
            const netid = this.state.netid;
            const path = `/asset/inventory/${encodeURIComponent(capAsset.asset_tag)}`;
            const requestBody = {
                condition_code: capAsset.condition_code,
                building_code: capAsset.building_code,
                room_number: capAsset.room_number,
                netid
            };
            const result = await makeApiCall({ method: 'put', path, requestBody, idToken });
            console.info(result);

            const assetTag = capAsset.asset_tag;
            capAsset.asset_tag = '';
            capAsset.condition_code = '';
            capAsset.capital_asset_number = '';
            capAsset.capital_asset_description = '';
            capAsset.serial_number = '';
            capAsset.organization = '';
            this.setState({ capAsset, successMessage: `* The asset tag ${assetTag} has been logged`, errorMessage: '', loading: false });
            this.assetTagInputRef.current.focus();
        } catch (error) {
            const errorMessage = `Error Submitting CapAsset Update to KFS: ${error && error.message}`;
            this.setState({ errorMessage, loading: false });
            this.saveButtonRef.current.scrollIntoView(false);
        }

    };

    showHelpPopup = async () => {
        this.setState({ helpMessage: contactHelpMessage });
    };

    scanButtonClicked = event => {
        event.preventDefault();
        const stateToUpdate = { scanning: !this.state.scanning };
        if (stateToUpdate.scanning) {
            const capAsset = { ...this.state.capAsset, asset_tag: '' };
            stateToUpdate.capAsset = capAsset;
        }
        this.setState(stateToUpdate);
    }

    onBarcodeDetected = result => {
        console.info('Home.onBarcodeDetected', result);
        const assetTag = result && result.codeResult && result.codeResult.code;
        if (assetTag) {
            const capAsset = { ...this.state.capAsset };
            capAsset.asset_tag = assetTag;
            this.setState({ scanning: false, capAsset });
            this.handleAssetTagApiCall(assetTag);
        }
    }

    render() {
        return (
            <div id="bodyContainer">

                <div className="medium-4 medium-offset-8 columns end">
                    <div className="button-bar">
                        <ul className="button-group radius round">
                            <li><span className="button" onClick={this.showHelpPopup}>Need Help?</span></li>
                        </ul>
                    </div>
                </div>

                <br/>
                <br/>

                <h1><BannerMessage bannerMessage={this.state.helpMessage} /></h1>

                <form onSubmit={this.handleSave}>

                    <div className="row">
                        <div className="small-9 small-offset-3 columns end">
                            <InfoMessage infoMessage={this.state.successMessage} />
                        </div>
                    </div>

                    <BuildingNameField
                        capAsset={this.state.capAsset}
                        handleBuildingInputChange={this.handleBuildingNameInputChange}
                        handleBuildingSearch={this.handleBuildingNameSearch}
                        handleBuildingChange={this.handleBuildingChange} />

                    <BuildingCodeField
                        capAsset={this.state.capAsset}
                        handleBuildingInputChange={this.handleBuildingCodeInputChange}
                        handleBuildingSearch={this.handleBuildingCodeSearch}
                        handleBuildingChange={this.handleBuildingChange} />

                    <RoomField
                        capAsset={this.state.capAsset}
                        loadingRoomLookup={this.state.loadingRoomLookup}
                        handleRoomInputChange={this.handleRoomInputChange}
                        handleRoomChange={this.handleRoomChange}
                        rooms={this.state.rooms} />

                    <AssetTagField
                        capAsset={this.state.capAsset}
                        scanning={this.state.scanning}
                        assetTagInputRef={this.assetTagInputRef}
                        scannerRef={this.scannerRef}
                        isCameraAvailable={this.state.isCameraAvailable}
                        scanButtonClicked={this.scanButtonClicked}
                        onBarcodeDetected={this.onBarcodeDetected}
                        handleAssetTagChange={this.handleAssetTagChange}
                        handleAssetTagKeyPress={this.handleAssetTagKeyPress} />

                    <ConditionField
                        capAsset={this.state.capAsset}
                        handleConditionChange={this.handleConditionChange} />

                    <div className="row">
                        <div className="small-9 small-offset-3 columns end">
                            <ErrorMessage errorMessage={this.state.errorMessage} />
                            <button type="submit" id="saveButton"
                                    ref={this.saveButtonRef} className="button">Save</button>
                        </div>
                    </div>

                </form>

            </div>
        );
    }
}

export default withRouter(Home);
